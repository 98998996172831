@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.positions-expandable-card {
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    padding: 15px 22px;
  }

  .expand-button {
    padding: 14px 0;
    width: 100%;
    color: variables.$color-blue;
    transition: color 0.15s;
    font-weight: 500;

    &:hover {
      color: variables.$color-primary-hover;
    }
  }

  .rah-static {
    position: relative;

    &--height-specific:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      z-index: 1;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      opacity: 1;
      animation: fade-in 0.25s;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

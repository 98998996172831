@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

@mixin table-to-desktop {
  @media (min-width: #{variables.$tablet}) and (max-width: #{variables.$desktop - 1px}) {
    @content;
  }
}

@mixin font-size-line-height-equal($font-size) {
  font-size: $font-size;
  line-height: $font-size;
}

$margin-below-detail: 6px;
$text-opacity: 0.7;
$card-side-padding: 16px;

.overview-card {
  &.card {
    padding: 0;
    margin: 0;
    border: 0;
  }
  .overview-card__body {
    padding: 20px $card-side-padding;
    overflow: hidden;

    .overview-card__summary {
      border-bottom: 2px solid variables.$color-mediumgray;
      margin-bottom: 2rem;

      @include media.tablet {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .overview-card__loading-blur {
    display: block;
    filter: blur(8px);
  }

  .overview-card__header {
    margin-bottom: 1.4rem;

    h2 {
      margin-bottom: $margin-below-detail;
      font-size: 18px;
    }

    .overview-card__header-wrapper {
      display: flex;
    }

    .overview-card__header-amount {
      font-weight: bold;
      margin-right: 6px;
      @include font-size-line-height-equal(3.6rem);

      @include media.landscape {
        @include font-size-line-height-equal(4.8rem);
      }

      .amount-currency {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: bold;
        opacity: 0.7;
        letter-spacing: 0.05em;
        position: absolute;
        right: -14px;
        top: 6px;

        @include media.landscape {
          top: 6px;
        }

        @include media.tablet {
          top: 7px;
        }

        @include media.desktop {
          top: 10px;
        }
      }
    }

    .overview-card__header-currency {
      font-size: 1.4rem;
      font-weight: bold;
      opacity: $text-opacity;
    }
  }

  .overview-card__information-text {
    opacity: $text-opacity;
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    margin: 0 0 $margin-below-detail 0;

    @include media.tablet {
      font-size: 1.8rem;
    }
  }

  .overview-card__information-text.overview-card__elevio {
    display: flex;
    opacity: 1;

    @include table-to-desktop {
      justify-content: flex-end;
    }

    .overview-card__elevio-text {
      opacity: $text-opacity;
    }

    .overview-card__elevio-button {
      border: 0;
      cursor: help;
      padding: 0;
      background-color: transparent;
      width: 17px;
      height: 16px;
      border-radius: 50%;
      color: variables.$color-blue;
      margin-left: 0.5rem;
    }
  }

  .overview-card__information {
    display: flex;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    @include table-to-desktop {
      flex-direction: column;
      text-align: right;
    }

    .overview-card__information-value {
      margin: 0 4rem 0.5rem 0;

      @include table-to-desktop {
        margin-right: 0;
      }
    }
  }

  .overview-card__earnings {
    display: flex;

    .overview-card__earnings--mr {
      margin-right: 3rem;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.edit-allocation-page {
  .col {
    display: flex;
    flex-direction: row;

    > * {
      width: 100%;
    }
  }

  h1 {
    display: flex;

    .header-account-title {
      margin-right: 0.2em;
    }

    .header-account-name {
      opacity: 1;
      display: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      color: variables.$color-lighttext;

      &::before {
        content: " - ";
      }

      @include media.tablet {
        display: block;
      }
    }
  }

  .edit-allocation-update-buttons {
    .update-button,
    .reset-button {
      margin: 0 0 10px 0;

      @include media.tablet {
        margin: 0;
        display: inline-block;
        width: calc(50% - 25px);

        &:first-child {
          margin: 0;
        }
      }
    }

    .reset-button {
      @include media.tablet {
        margin: 0 0 0 50px;
      }
    }
  }

  .edit-allocation-investment-type-selection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      flex: 0 0 auto;
    }

    & > div {
      flex: 1 0 auto;
    }

    .investment-type-selection-fg {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.account-page-allocation {
  .account-page-investment-type,
  .account-page-investment-horizon {
    display: flex;
    justify-content: space-between;
  }

  .account-page-investment-type {
    margin-top: 12px;

    .risk-indicator {
      margin: 0 0 0 6px;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/media";

.card-bottom {
  text-align: center;
  background-color: var(--lysa-background-card-bottom);

  a {
    padding: 14px 16px;
    text-decoration: none;
    width: 100%;
    display: block;
    font-weight: 500;

    @include media.landscape {
      padding: 14px 24px;
    }
  }
}

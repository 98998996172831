@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.edit-allocation-summary {
  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > div {
    flex: 1 0 auto;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  .kf-info {
    *:last-child {
      margin-bottom: 0;
    }
  }
}

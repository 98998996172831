@use "~@lysaab/ui-2/components/styling/variables";

.bank-selection {
  h2 {
    text-align: center;
  }
  .search-input {
    display: block;
    width: 100%;

    label {
      height: 1px;
      opacity: 0;
      position: absolute;
    }
  }

  .bank-selection-list {
    width: 100%;

    .card-list-item {
      min-height: 72px;
      justify-content: start;
      align-items: center;
      font-weight: 700;

      .bank-icon {
        flex-shrink: 0;
      }

      .card-list-item__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: left;

        svg {
          flex-shrink: 0;
        }
      }
    }

    .icon-wrapper {
      border-radius: 50%;
      background-color: #e1e1e1;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;

      .icon-primary {
        fill: var(--lysa-icon-secondary);
      }
    }

    .missing-bank-link {
      .missing-bank-text {
        display: flex;
        width: 100%;
        justify-content: center;
        color: variables.$color-text;
        text-align: center;

        .missing-bank-text-description {
          font-weight: normal;
        }
      }
    }

    .refine-card {
      font-weight: normal;

      svg {
        margin-right: 10px;
      }
    }
  }
}

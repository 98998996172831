.account-page-close-account {
  .card .lysa-ui-snackbar {
    padding: 16px 24px 16px 24px;
    margin-bottom: 0px;

    .account-page-close__snackbar-close {
      text-align: left;
    }
    .account-page-close-account__list {
      text-align: left;
      .account-page-close-account__list-entry {
        list-style: initial;
        margin-bottom: 10px;
        margin-left: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

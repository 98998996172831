.amount {
  font-weight: 500;
  position: relative;

  .amount-currency {
    opacity: 0.7;
    margin: 0;
    font-weight: normal;
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$bottom-margin: 8rem;

.shared-account-page {
  .page-header {
    padding: 30px 0 10px 0;

    h1 {
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .shared-account-page__header {
    margin-bottom: 4px;
  }

  .shared-account-page__subheader {
    display: flex;
    font-size: 1.2em;
    margin-bottom: 12px;
    align-items: center;

    .icon {
      font-size: 1.3em;
      color: variables.$color-blue;
      margin-right: 6px;
    }

    span {
      opacity: 0.7;
    }
  }

  .shared-account-page__grid {
    margin-top: 80px;
  }

  .shared-account-page__section-wrapper {
    margin-bottom: $bottom-margin;

    .shared-account-page__section {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > section {
        flex-grow: 1;
      }

      .card {
        margin: 0;
      }

      .card-list {
        margin: 0;
        flex-grow: 1;
      }
    }

    .account-page-card-body {
      padding: 17px 16px;

      @include media.landscape {
        padding: 17px 22px;
      }
    }
  }

  .disclaimer {
    opacity: 0.5;
    font-size: 11px;
    line-height: 1.5;
    margin: 20px 14px 0 14px;

    @include media.tablet {
      margin: 20px 24px 0 24px;
      font-size: 13px;
    }
  }
}

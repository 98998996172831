@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-actions {
  padding: 40px 0 0 0;

  .account-actions-buttons {
    .lysa-ui-button {
      margin: 10px 0 0 0;
    }

    @include media.tablet {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      column-gap: 20px;
      row-gap: 80px;
      align-items: stretch;

      .lysa-ui-button {
        margin: 0;
      }
    }

    @include media.desktop {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media.desktop-plus {
      column-gap: 40px;
    }
  }

  .action-deposit {
    grid-column: 1 / 2;
  }

  .action-internal {
    grid-column: 2 / 3;

    + .action-withdrawal {
      grid-column: 3 / 4;
    }
  }

  .action-withdrawal {
    grid-column: 2 / 3;
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.edit-allocation-settings {
  gap: 10px;

  h5 {
    margin: 0 0 6px 0;
  }

  @include media.landscape {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  .edit-allocation-settings-monthly,
  .edit-allocation-settings-worth {
    min-width: 200px;
    flex: 1;
  }

  .edit-allocation-settings-risk {
    position: relative;
    flex: 1;
    min-width: 50%;
  }
}

.edit-allocation-page {
  .forecast-graph {
    margin: 0;

    .plot-graph {
      @include media.tablet {
        min-height: 300px;
      }
    }
  }

  .edit-allocation-graph {
    .lysa-ui-chip {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0;

      & > label {
        flex: 0 0 calc(25% - 18px);
        margin: 0;
      }

      & > div {
        display: none;
      }
    }
  }
}

.edit-allocation-account-declaration {
  &.is-insurance-account {
    p {
      margin: 14px 0 40px 0;
    }

    @include media.desktop {
      p {
        margin: 14px 0;
      }

      .card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & > div {
          flex: 0 0 calc(50% - 25px);
        }
      }
    }
  }
}

.bank-id-qr-wrapper {
  margin-bottom: 0;
  height: 172px;
  width: 172px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .spinner-wrapper {
    height: 40px;
    width: 40px;
  }
}

@use "~@lysaab/ui-2/components/styling/media";

.login-nets-page {
  padding: 0 16px;
  position: relative;

  @include media.landscape {
    padding: 0 24px;
  }

  @include media.desktop {
    padding: 0 24px;
  }

  header {
    margin: 24px auto;
    text-align: center;
  }

  .center-wrapper {
    max-width: 500px;
    margin: 0 auto;

    h1,
    p {
      text-align: center;

      .fi {
        box-shadow: 0px 0px 1px 0px #acacac;
        margin-right: 8px;
      }
    }
  }

  .login-footer {
    margin-top: 24px;
    text-align: center;

    &.initial-load {
      visibility: hidden;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.event-bar {
  background-color: variables.$color-lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-weight: 500;
  font-size: 16px;

  @include media.tablet {
    padding: 12px 24px;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
  }

  .right-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-button {
    margin-left: 12px;
    line-height: 0;
  }

  @media print {
    display: none;
  }
}

.account-page-transactions {
  .transaction-entry {
    display: flex;
    justify-content: space-between;
  }

  .account-page-transactions-list {
    position: relative;
  }

  .additional-transactions-indicator {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  &.has-additional-transactions {
    .additional-transactions-indicator {
      display: block;
    }
  }
}

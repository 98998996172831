@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-kyc {
  .futur-kyc-subtitle {
    margin: 0 0 40px 0;
  }

  .type-of-business-additional {
    margin: -2px 0 0 35px;
  }

  .create-account-corporation-kyc-top {
    position: relative;

    h1 {
      margin: 0 0 34px 0;

      @include media.tablet {
        margin: 0 0 14px 0;
      }
    }

    @include media.tablet {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    & > div {
      position: absolute;
      bottom: -22px;
      left: 2px;

      @include media.tablet {
        position: relative;
        left: 0;
        bottom: 0;
      }
    }
  }

  img {
    max-width: 100px;

    @include media.tablet {
      flex: 1 0 auto;
      max-width: 120px;
    }
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 100px;
    resize: none;
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;

    &:active,
    &:focus,
    &:hover {
      border: 1px solid variables.$color-blue;
    }
  }

  .corporation-type {
    position: relative;

    .as-link {
      position: absolute;
      top: 3px;
      right: 0;
      font-weight: 400;
      z-index: 3;
    }
  }

  h4 {
    margin: 50px 0 14px 0;

    &:first-child {
      margin: 0 0 14px 0;
    }
  }
}

.corp-definitions {
  padding: 30px 0 50px 0;
  position: relative;

  @include media.desktop {
    padding: 30px 160px 50px 0;
  }

  ul {
    margin: 10px 0 20px 20px;
    list-style-type: disc;
  }

  li {
    margin: 0 0 6px 0;
  }

  ol {
    margin: 10px 0 20px 20px;
    list-style-type: decimal;

    li {
      margin: 0 0 6px 0;
    }
  }

  img {
    position: absolute;
    right: 0;
    top: 10px;
    width: 120px;
  }
}

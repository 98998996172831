@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$background-position: 90% 35%;

.savings-account-card {
  background: url(./accountIcon/SavingsAccountIcon.svg) no-repeat;
  background-position: $background-position;
  margin-bottom: 1rem;
}

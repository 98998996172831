@use "~@lysaab/ui-2/components/styling/variables";

.transactions-card {
  .transactions-card__ingress {
    font-weight: bold;
  }

  .transaction-card__list {
    position: relative;

    .transaction-card__entry {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      .transaction-card__entry-left {
        width: 50%;

        .transaction-card__entry-header {
          font-style: italic;
        }
      }

      .transaction-card__entry-right {
        text-align: right;
        width: 50%;
      }

      .transaction-card__entry-header {
        font-size: 12px;
        color: variables.$color-lighttext;
      }

      .transaction-card__entry-indicator {
        display: inline-block;
        margin: 0 4px 0 0;
        border-radius: 2px;
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }

      .transaction-card__deposit-indicator {
        background-color: variables.$color-green;
      }

      .transaction-card__withdrawal-indicator {
        background-color: variables.$color-red;
      }

      .transaction-card__internal-transfer-indicator {
        background-color: variables.$color-blue;
      }
    }

    .transaction-card__list-fade {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}

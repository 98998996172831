@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.edit-allocation-investment-basis-suitability {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > div {
    flex: 1 0 auto;
  }

  p {
    margin-top: 0;
  }

  .lysa-ui-button {
    align-items: center;
  }

  .additional-text {
    margin-bottom: 1.4rem;
    color: variables.$color-lighttext;
  }
}

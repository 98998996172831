.confirmation-text-section {
  .confirmation-text {
    text-align: left;
    margin-bottom: 14px;

    ul {
      margin-top: 4px;
      list-style: disc !important;
      margin-left: 18px;

      li {
        margin-bottom: 4px;
      }
    }
  }
}

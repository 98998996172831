@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.edit-allocation-investment-basis-horizon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .risk-indicator {
      margin-left: 6px;
    }
  }

  & > div {
    flex: 1 0 auto;
  }

  dl {
    margin: 0;

    > div {
      display: flex;
      width: 100%;
      margin-bottom: 14px;
    }
  }

  dt {
    font-weight: 700;
  }

  dd {
    text-align: right;
    margin-left: auto;
  }
}

.account-page-followed-account {
  width: 100%;
  padding: 0;

  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
}

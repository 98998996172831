.see-no-evil-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px;

  .emoji {
    font-size: 3em;
  }

  .header {
    margin-bottom: 0;
  }
}

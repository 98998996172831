@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.withdrawal-kf-request-page {
  h1 {
    margin: 0 0 40px 0;
  }

  .withdrawal-disclaimer {
    margin: 40px 0 0 0;
  }
}

.withdrawal-request-page-kf-signers,
.withdrawal-request-page-done,
.withdrawal-request-page-confirm,
.withdrawal-request-page-kf-transaction {
  .withdrawal-item,
  .withdrawal-item-wrapper {
    margin: 30px 0;

    @include media.tablet {
      margin: 50px 0;
    }

    .withdrawal-item {
      margin: 20px 0 0 0;
    }
  }

  .withdrawal-item-partial-name {
    display: flex;

    b {
      display: block;
      flex: 0 0 auto;
      padding: 0 6px 0 0;
    }

    i {
      display: block;
      flex: 0 1 auto;
      opacity: 0.7;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.withdrawal-request-page-kf-transaction {
  &.is-emptying {
    .money-input {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .transaction-inputs {
    @include media.tablet {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      & > div {
        flex: 1 0 auto;
        padding: 0 20px 0 0;
      }

      & > .lysa-ui-checkbox-group {
        flex: 0 0 auto;

        legend {
          margin: 0;
        }
      }
    }

    input[type="checkbox"] + label {
      @include media.tablet {
        padding: 10px 16px 10px 48px;
      }
    }
  }
}

.withdrawal-request-page-kf-sign {
  text-align: center;

  .bankid-logo {
    margin: 50px 0 0 0;
  }

  .bankid-button {
    margin: 50px 0 0 0;
  }

  .lysa-ui-spinner {
    max-width: 50px;
  }
}

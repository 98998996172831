.confirmation-summary {
  margin-top: 24px;

  .confirmation-summary__risk-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    .risk-indicator {
      margin: 0 0 0 5px;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-edit-allocation {
  .forecast-tooltip-header {
    text-transform: none;
    min-height: 38px;
    margin: 0;
  }

  .kf-tax {
    @include media.tablet {
      display: block;
    }
  }

  .forecast-tooltip {
    li {
      text-transform: none;
      margin: 5px 0;
      letter-spacing: 0.02em;
    }
  }

  .graph-tooltip-short-interval {
    text-transform: none;
  }

  .reset-button {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity 300ms ease;

    &.active {
      opacity: 1;
    }
  }
}

.create-account-corporation-fees {
  position: relative;

  .create-account-corporation-fees-top {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid variables.$color-lightgray;
  }

  .total-row {
    border-bottom: 0;
    font-weight: 700;
  }

  p {
    margin: 30px 0 20px 0;
    font-size: 13px;
    line-height: 1.5;
    opacity: 0.8;
  }

  .create-account-corporation-tax {
    h4 {
      margin: 30px 0 14px 0;
    }
  }

  .amount {
    display: inline-block;
  }

  .create-account-corporation-tax-monthly {
    margin: 30px 0 0 0;
  }
}

.create-account-corporation-positions {
  dt {
    padding: 4px 0 0 0;

    &.summary-cell {
      padding: 8px 0 0 0;
    }
  }
  dd {
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 0 4px 0;
    border-bottom: 1px solid variables.$color-lightgray;

    &.summary-cell {
      margin: 0 0 40px 0;
      border-bottom: 0;
      padding: 8px 0 4px 0;
    }
  }
  .summary-cell {
    font-weight: 700;
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
}

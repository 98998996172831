@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-futur-sign {
  text-align: center;

  .retry {
    .lysa-ui-snackbar {
      margin: 30px 0 10px 0;

      h5 {
        margin: 0 0 2px 0;
      }
    }

    .button-primary {
      margin: 40px 0 0 0;
    }
  }

  p {
    opacity: 0.6;
    color: variables.$color-black;
  }

  .return-button {
    text-align: center;
    margin: 10px 0 0 0;
  }

  .lysa-ui-spinner {
    max-width: 60px;
  }

  .bankid-logo {
    display: block;
    margin: 50px 0 6px 0;
  }

  .bankid-button {
    opacity: 0;
    transition: opacity 300ms ease;
    pointer-events: none;
  }

  &.has-token {
    .bankid-button {
      opacity: 1;
      pointer-events: all;
    }
  }
}

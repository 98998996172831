.investment-type-card {
  overflow: hidden;
  position: relative;

  h3 {
    text-transform: capitalize;
  }

  svg {
    display: block;
    margin: 0;
    width: 280px;
    height: 280px;
  }

  .button-primary {
    margin: 20px 0 0 0;
  }

  .leaf {
    path {
      fill: #f1f5f9;
    }
  }

  .earth {
    circle {
      fill: #f1f5f9;
    }
  }

  .investment-type-selection-bg {
    position: absolute;
    z-index: 0;
    right: 10px;
    top: 20px;
    height: auto;
    width: 60%;
    max-width: 280px;
  }

  .investment-type-selection-fg {
    position: relative;
    z-index: 1;
  }
}

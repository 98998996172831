.see-no-evil-toggle {
  display: flex;

  .lysa-ui-button {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-fees-data {
  dl {
    margin: 10px 0 50px 0;
    display: flex;
    flex-wrap: wrap;
  }

  dt,
  dd {
    vertical-align: top;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0 4px 0;
    margin: 0;
    flex: 0 0 auto;
  }

  dd {
    width: 100px;
    text-align: right;
  }

  dt {
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .summary-cell {
    border-bottom: 0;
  }

  .lysa-ui-snackbar {
    padding: 12px 12px;
    font-size: 12px;
    line-height: 1.6;

    @include media.tablet {
      padding: 16px;
      font-size: 14px;
      line-height: 1.8;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-positions-data {
  position: relative;

  .cash-assets {
    position: absolute;
    top: -39px;
    right: 0;
    font-size: 14px;

    @include media.desktop-plus {
      font-size: 16px;
    }
  }

  .account-page-positions-data-bonds {
    .cash-assets {
      display: none;
    }

    @include media.tablet {
      .cash-assets {
        display: block;
      }
    }
  }

  .account-page-positions-data-stocks {
    @include media.tablet {
      .cash-assets {
        display: none;
      }
    }
  }

  .account-page-positions-data-stocks {
    margin: 0 0 40px 0;

    @include media.tablet {
      margin: 0;
    }
  }

  &.single-asset {
    .account-page-positions-data-stocks,
    .account-page-positions-data-bonds {
      margin: 0;

      .cash-assets {
        display: block;
      }
    }
  }

  .amount {
    display: inline-block;
  }

  .account-page-positions-data-funds-wrapper {
    @include media.tablet {
      display: flex;
      justify-content: space-between;
    }
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .account-page-positions-data-stocks,
  .account-page-positions-data-bonds {
    position: relative;
    display: flex;
    flex-direction: column;

    .card {
      min-height: 400px;
    }

    @include media.tablet {
      flex: 0 0 auto;
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    @include media.desktop {
      flex-basis: calc(50% - 10px);
    }

    @include media.desktop-plus {
      flex-basis: calc(50% - 20px);
    }
  }
}

@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.signup-docs {
  .link-container {
    font-size: 16px;
    line-height: 1.8;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

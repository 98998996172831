@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-futur-summary {
  .summary-top {
    font-size: 16px;
    line-height: 1.8;
    margin: 0 0 40px 0;
  }

  .futur-summary-additional-contact {
    .lysa-ui-snackbar {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 0;

      b {
        margin: 0 6px 0 0;
      }
    }
  }

  .lysa-ui-spinner {
    max-width: 150px;
  }

  .futur-preinfo {
    ul {
      list-style-type: disc;
      margin: 0 0 40px 0;
    }

    li {
      margin: 0 0 10px 20px;
    }

    .application-receipt {
      transition: opacity 300ms ease;
      opacity: 0;
      pointer-events: none;
    }

    &.show-receipt {
      .application-receipt {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .signers {
    li {
      margin: 0 0 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .email-wrapper {
    margin: 40px 0 0 0;

    @include media.landscape {
      margin: 30px 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .lysa-ui-input-group {
      @include media.landscape {
        flex: 1 0 auto;
        padding: 0 20px 0 0;
      }
    }

    .button-primary {
      display: block;

      @include media.landscape {
        flex: 0 1 auto;
        position: relative;
        top: 34px;
      }
    }
  }
}

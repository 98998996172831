@use "~@lysaab/ui-2/components/styling/colors";
@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$heading-div-height: 30px;

.layout {
  background-color: var(--lysa-brand-blue);
  padding: 16px;
  border-radius: 10px 10px 0 0;
  transition: border-radius 0.3s;
  //Constrains animations within card
  position: relative;
  overflow: hidden;

  &.account-page {
    background: none;
    border-bottom: 1px solid variables.$color-mediumgray;
    .wrapper {
      .header {
        color: var(--lysa-text-secondary);
      }

      .number {
        color: var(--lysa-text-primary);
      }
    }
  }

  &.hidden-graph {
    border-radius: 10px;
    &.savings-account-page {
      border-radius: 10px 10px 0 0;
    }
  }

  &.see-no-evil-mode {
    border-radius: 10px;
  }

  .card-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin-bottom: -8px;
  }

  .wrapper {
    &.is-loaded {
      .number {
        filter: blur(0px);
      }
    }

    &.no-earnings {
      justify-content: flex-start;
      border-radius: 10px 10px 10px 10px;
    }

    .typography--display-large {
      font-size: 34px;
    }

    @media only screen and (min-width: 360px) {
      .typography--display-large {
        font-size: 40px;
      }
    }

    @media only screen and (min-width: 410px) {
      .typography--display-large {
        font-size: 48px;
      }
    }

    .typography--display-small {
      font-size: 24px;
    }

    .amount {
      font-weight: 600;
      .amount-currency {
        opacity: 1;
        font-weight: 600;
      }
    }

    .header {
      color: var(--lysa-text-summary-blue);
    }

    .total-worth-wrapper {
      display: flex;
      flex-direction: column;

      .total-worth-header {
        display: flex;
        z-index: 2;
      }
    }

    .number {
      color: var(--lysa-text-white);
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: filter 300ms ease;
      filter: blur(8px);
      z-index: 2;

      .percent-sign {
        opacity: 1;
        font-size: 1em;
        font-weight: 600;
      }
    }

    .yield-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 8px;
      z-index: 2;

      .yield-header {
        display: flex;
        z-index: 2;
        align-items: center;
      }

      .yield-circle-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        margin-left: 5px;
        cursor: pointer;
        padding: 0;
        -webkit-tap-highlight-color: transparent;
        border: none;

        .icon-primary {
          fill: var(--lysa-text-white);
        }
      }

      .yields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 4px;
      }
    }

    &.account-page {
      .header {
        color: var(--lysa-text-secondary);
      }

      .number {
        color: var(--lysa-text-primary);
      }
    }
  }

  .header-circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    border: solid 1px var(--lysa-text-summary-blue);
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 2;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    .icon-primary {
      fill: var(--lysa-text-white);
    }

    &.showing-graph {
      background-color: var(--lysa-text-white);
      border-color: var(--lysa-text-white);
      .icon-primary {
        fill: var(--lysa-brand-blue);
      }
    }
  }

  &.account-page {
    .yield-wrapper {
      .yield-circle-button {
        .icon-primary {
          fill: var(--lysa-text-primary);
        }
      }
    }
    .header-circle-button {
      border: solid 1px var(--lysa-text-secondary);

      .icon-primary {
        fill: var(--lysa-text-primary);
      }

      &.showing-graph {
        background-color: var(--color-blue-gray-100);
        border-color: var(--color-blue-gray-100);
      }
    }
  }

  //Solves sticky hover effect on button click for browsers without native hover (i.e mobile)
  @media (hover: hover) {
    .header-circle-button:hover {
      background-color: var(--lysa-primary-hover);
      transition: background-color 0.3s;
    }

    .header-circle-button.showing-graph:hover {
      background-color: var(--lysa-text-summary-blue);
      border-color: var(--lysa-text-summary-blue);
      transition: background-color 0.3s;
      .icon-primary {
        fill: var(--lysa-brand-blue);
      }
    }

    &.account-page {
      .header-circle-button:hover {
        background-color: var(--lysa-button-secondary-hover);
        transition: background-color 0.3s;
      }
      .header-circle-button.showing-graph:hover {
        background-color: var(--lysa-button-secondary-hover);
        border-color: var(--lysa-button-secondary-hover);
        transition: background-color 0.3s;
        .icon-primary {
          fill: var(--lysa-text-primary);
        }
      }
    }
  }
}

//Tablet and upwards
@include media.tablet {
  .layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 32px;

    .card-actions {
      order: 2;
      flex-grow: 1;
    }

    .wrapper {
      display: flex;
      flex-grow: 2;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 0;

      .balance-and-earnings {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.savings-account-wrapper {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
      }

      .yield-wrapper {
        margin-top: 0;
        .yields {
          flex-direction: row;
          column-gap: 8px;
          .typography--display-small {
            font-size: 48px;
          }
        }
      }
    }
  }
}

//Desktop and upwards
@include media.desktop {
  .layout {
    .wrapper {
      flex-direction: row;

      .balance-and-earnings {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        gap: 24px;

        &.savings-account-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &.is-savings-account-page {
        flex-direction: column;
      }

      .savings-account-available {
        display: flex;
      }

      .typography--display-small {
        font-size: 48px;
      }
      .total-worth-wrapper {
        .number {
          min-height: 60px;
        }
      }

      .yield-wrapper {
        .yields {
          .number {
            .typography--display-small {
              font-size: 48px;
            }
          }
        }
      }
    }

    .card-actions {
      gap: 8px;
    }
  }
}

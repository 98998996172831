@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.invite-link {
  .invite-link-link {
    border-bottom: 1px dashed var(--lysa-border-black);
    margin-bottom: 2rem;
    overflow-wrap: anywhere;
    cursor: pointer;
  }

  .invite-link-share {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
    }
  }

  .invite-link-qrcode {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 0px;
    
    canvas {
      width: 100%;
      height: auto;
      max-height: 160px;
      max-width: 160px;
      border: 3px solid variables.$color-black;
      padding: 0px;
      border-radius: 8px;
    }
  }

  .lysa-ui-button {
    width: 100%;
    margin-bottom: 1rem;

    &.button-medium:not(.block) {
      width: 100%;
    }

    + .lysa-ui-button {
      margin-left: 0;
    }
  }

  .success-bar {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: var(--lysa-state-success);
  }

  .success-text {
    position: absolute;
    font-size: 1rem;
    height: 2.25rem;
    color: var(--lysa-state-success);
    bottom: -2rem;
  }

  .success-init {
    position: relative;

    .success-text {
      transition: opacity 0.5s ease 30ms;
      will-change: opacity;
      opacity: 0;
    }

    .success-bar {
      transition: opacity, width 0.25s ease;
      will-change: opacity, width;
      opacity: 0;
      width: 50%;
    }
  }

  .has-success {
    .success-bar,
    .success-text {
      opacity: 1;
      width: 100%;
    }
  }
}

.news-banner {
  .lysa-ui-snackbar > div {
    width: 100%;
  }

  .lysa-ui-snackbar {
    padding: 16px;
  }

  .news-banner__content-wrapper {
    display: flex;
    align-items: flex-start;

    .news-banner__content {
      width: 100%;
      text-align: left;
    }

    .news-banner__close-button {
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0 0 0 10px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}

@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.pending-kf-status {
  margin: 4px 0 0 0;
  line-height: 1.5;

  .icon-wrapper {
    margin: 0 5px 0 0;
    position: relative;
    top: 4px;
  }
}

.pending-withdrawal {
  margin: 20px 0;
  padding: 20px 0 0 0;
  border-top: 1px solid variables.$color-mediumgray;

  &:first-child {
    border-top: 0;
  }

  li {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    overflow: visible;
  }
}

.pending-withdrawal .pending-withdrawal-abort {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";
@use "../styling/layout";

header {
  max-width: variables.$desktop-plus;
  margin: 0 auto;

  ul {
    display: flex;
    align-items: center;

    li.tablet-up-only {
      display: none;
    }

    li {
      margin: 0 0 0 24px;
    }
  }

  & > a {
    margin: 5px 0 0 0;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: variables.$color-text;
    color: variables.$color-blue;
    height: 20px;
  }

  .profile-indicator {
    position: relative;
    top: -5px;
    margin: 0 0 0 3px;
  }

  .left-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;

    .open-burger-menu {
      position: relative;
      margin-right: 24px;

      .icon-primary {
        fill: var(--lysa-icon-secondary);
      }
    }
  }

  .read-only-warning {
    margin: 0;
    margin-left: 12px;
    overflow: hidden;

    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      display: none;
      margin-right: 8px;
    }

    @include media.tablet {
      svg {
        display: inline-block;
      }
    }
  }
}

.page {
  & > header {
    height: layout.$header-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.is-authenticated {
  @include media.tablet {
    header {
      ul {
        li.tablet-up-only {
          display: initial;
        }
      }
    }
  }
  .page {
    & > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.create-account-corporation-lysa-summary {
  h4 {
    margin: 0 0 20px 0;
    svg {
      margin: 0 10px 0 0;
      position: relative;
      top: 3px;
    }
  }

  dd,
  dt {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin: 0 0 8px 0;
    font-size: 15px;
  }

  dt {
    opacity: 0.7;
  }

  dd {
    text-align: right;
    font-weight: 500;
  }

  .create-account-corporation-lysa-summary-risk-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    .risk-indicator {
      margin: 0 0 0 5px;
    }
  }

  .risk-indicator-container,
  .target {
    width: 100%;
    font-weight: 400;

    i {
      opacity: 0.7;
    }
  }

  .stock-number {
    text-align: left;
  }

  .futur-preinfo {
    margin: 40px 0 50px 0;

    ul {
      list-style: disc;
      margin: 0 0 0 20px;
    }

    li {
      margin: 0 0 10px 0;
      line-height: 1.4;
    }
  }
}

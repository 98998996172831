@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-monthly,
.account-page-transactions,
.account-page-allocation {
  @include media.tablet {
    .card-list {
      display: flex;
      flex-direction: column;
    }

    .account-page-padder {
      flex: 1 0 auto;
    }

    .retry,
    .retry-wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
  }
}

.account-page-padder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .account-page-card-body {
    flex: 1 0 auto;
  }

  .card-bottom {
    flex: 0 0 auto;
  }
}

.compressed-snackbar {
  padding: 38px 0 0 0;
}

.account-page-allocation {
  @include media.tablet {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-list {
      flex: 1 0 auto;
    }
  }
}

.account-page-transactions {
  @include media.tablet {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-list {
      flex: 1 0 auto;
    }
  }
}

.account-page-monthly {
  @include media.tablet {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-list {
      flex: 1 0 auto;
    }
  }

  @include media.desktop {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
}

.account-page-positions-data,
.account-page-positions-example {
  @include media.tablet {
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    &.single-asset {
      .disclaimer {
        max-width: calc(50% - 84px);
      }
    }
  }

  @include media.desktop {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
}

.account-page-fees-data,
.account-page-fees-example {
  @include media.tablet {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  @include media.desktop {
    grid-column: 3 / 4;
    grid-row: 2 / 2;
  }
}

.account-page-share-account {
  @include media.tablet {
    grid-column: 1 / 2;
  }

  @include media.desktop {
    grid-column: 1 / 2;
  }
}

.account-page-change-name {
  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card {
      flex: 1 0 auto;
    }
  }
}

.account-page-close-account {
  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card {
      flex: 1 0 auto;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.account-page-share-account {
  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card {
      flex: 1 0 auto;
    }
  }
}

.account-page-grid {
  margin: 0 0 40px 0;

  @include media.tablet {
    margin: 80px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 80px;
    align-items: stretch;
  }

  @include media.desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media.desktop-plus {
    column-gap: 40px;
  }
}

.account-page {
  .card-list,
  .card {
    margin: 0;
  }

  section {
    margin: 80px 0 0 0;

    @include media.tablet {
      margin: 0;
    }
  }

  a.lysa-ui-button {
    text-decoration: none;
  }

  .page-header {
    padding: 30px 0 10px 0;

    h1 {
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .account-page-card-wrapper {
    @include media.tablet {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .account-page-docs,
  .account-page-monthly,
  .account-page-allocation,
  .account-page-transactions,
  .account-page-fees-data,
  .account-page-fees-example,
  .account-page-share-account {
    .card-list {
      overflow: hidden;
    }

    .account-page-card-body {
      padding: 17px 16px;

      @include media.landscape {
        padding: 17px 22px;
      }
    }
  }

  .disclaimer {
    opacity: 0.5;
    font-size: 11px;
    line-height: 1.5;
    margin: 20px 14px 0 14px;

    @include media.tablet {
      margin: 20px 24px 0 24px;
      font-size: 13px;
    }
  }
}

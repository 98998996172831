@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-advice {
  .box-margin {
    margin: 24px 0;
  }

  .allocation-bar .allocation-bar-bonds > span,
  .allocation-bar .allocation-bar-stocks > span {
    height: 8px;
    border-radius: 4px;
  }

  .allocation-proposal {
    font-size: 18px;

    b {
      font-size: 22px;

      @include media.tablet {
        font-size: 26px;
      }
    }

    .allocation-proposal-investment-type {
      line-height: 1.8;
      position: relative;
      margin: 40px 0;

      @include media.tablet {
        margin: 40px 0 40px 0;
      }
    }

    .create-account-corporation-advice-indicator {
      display: flex;
      align-items: center;
    }
  }

  .allocation-bar {
    margin: 0px 0 0 0;
  }

  .allocation-bar .allocation-bar-stocks {
    padding: 0 4px 0 0;
  }

  .allocation-bar .allocation-bar-bonds {
    padding: 0 0 0 4px;
  }

  .allocation-bar-value {
    font-size: 22px;
    font-weight: bold;

    @include media.tablet {
      font-size: 26px;
    }
  }

  .allocation-bar-name {
    opacity: 0.7;
  }

  .stock-number {
    margin: 0 0 8px 0;

    .allocation-bar-value {
      margin: 0 8px 0 0;
      letter-spacing: -0.05em;
    }
  }

  .bonds-number {
    margin: 0 0 8px 0;

    .allocation-bar-value {
      margin: 0 0 0 8px;
      letter-spacing: -0.05em;
    }
  }
}

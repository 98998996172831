@use "~@lysaab/ui-2/components/styling/variables";

.bank-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  will-change: opacity;
  transition: opacity variables.$timer-standard ease-in-out;

  img {
    width: 100%;
  }
}

.invite-information {
  .invite-information-header {
    font-size: 2.5rem;
    display: grid;
    justify-content: center;
  }

  .invite-information-disclaimer {
    margin-top: 3rem;
    font-size: 1.2rem;
  }
}
